import { Box, SwipeableDrawer } from '@mui/material';
import PropTypes from 'prop-types';

// Our Components
import AssetProviderBox from 'components/NavMenu/AssetProviderBox';
import NavOptions from 'components/NavMenu/NavOptions';
import SimpleMobileAppBar from 'components/AppBar/SimpleMobileAppBar';
import SoraSkeleton from 'components/Loader/Skeleton';

// Hooks
import useGetAssetProvider from 'hooks/assetProvider/useGetAssetProvider';

const Nav = ({ isMenuOpen, toggleMenuOpen }) => {
	const { isLoading, isSuccess, data } = useGetAssetProvider();

	if (isLoading) {
		return <SoraSkeleton />;
	}

	if (isSuccess) {
		return (
			<SwipeableDrawer
				open={isMenuOpen}
				onOpen={toggleMenuOpen}
				transitionDuration={350}
				onClose={toggleMenuOpen}
				PaperProps={{
					sx: {
						background:
							'linear-gradient(180deg, #B0CDE4 0%, #FFF 100%)',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						width: '100%'
					}
				}}
			>
				<Box>
					<SimpleMobileAppBar toggleMenuOpen={toggleMenuOpen} />
					<NavOptions isMenuOpen={isMenuOpen} />
				</Box>

				<AssetProviderBox providerData={data} />
			</SwipeableDrawer>
		);
	}
};

Nav.propTypes = {
	isMenuOpen: PropTypes.bool.isRequired,
	toggleMenuOpen: PropTypes.func.isRequired
};

export default Nav;

// Our Helers
import ColumnItem from 'components/DataTable/ColumnItem';
import DataTable from 'components/DataTable/DataTable';

// Our utils
import { dollarFormatter } from 'shared/utils';
import normalizeTradelineType from 'shared/utils/formatting/normalizeTradelineType';

function LiabilityDataGrid({ liabilities }) {
	const tradeLineType = 'tradeLineType';
	const lender = 'lender';
	const interestRate = 'interestRate';
	const outstandingBalance = 'outstandingBalance';
	const monthlyMortgagePayment = 'monthlyMortgagePayment';

	const tableHeadings = [
		ColumnItem(tradeLineType, 'Type', 1, {
			valueGetter: (params) => {
				const currentTradelineType = params.row[tradeLineType];

				return normalizeTradelineType(currentTradelineType) ?? 'NA';
			},
			sortingOrder: ['asc', 'desc', null]
		}),

		ColumnItem(lender, 'Details', 1),
		ColumnItem(interestRate, 'Rate', 1, {
			valueFormatter: (params) => {
				const formattedInterestValue = `${params.value}%`;
				return formattedInterestValue;
			}
		}),
		ColumnItem(outstandingBalance, 'Balance', 1, {
			valueFormatter: (params) => {
				const formattedOutstandingBalance = `${dollarFormatter.format(
					params.value ?? 0
				)}`;
				return formattedOutstandingBalance;
			}
		}),
		ColumnItem(monthlyMortgagePayment, 'Monthly', 1, {
			valueFormatter: (params) => {
				const formattedMonthlyPayment = `${dollarFormatter.format(
					params.value ?? 0
				)}`;
				return formattedMonthlyPayment;
			}
		})
	];

	const liabilitiesWithId = liabilities.map((liability) => ({
		id: liability.tradelineId,
		...liability
	}));

	return (
		<DataTable
			columns={tableHeadings}
			rows={liabilitiesWithId}
			initialSortFieldName={tradeLineType}
		/>
	);
}

export default LiabilityDataGrid;

import { useMutation } from 'react-query';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoint urls
import { userLoginEndpoint } from 'shared/api-urls';

function useMutateAuthAsAdmin() {
	const auth = getAuth();

	return useMutation(async () => {
		const firebaseResponse = await signInWithEmailAndPassword(
			auth,
			process.env.REACT_APP_ADMIN_USER,
			process.env.REACT_APP_ADMIN_KEY
		);

		const firebaseIdToken = firebaseResponse.user.accessToken;

		const loginResponse = await axiosInstance.post(userLoginEndpoint, {
			firebaseIdToken
		});

		return loginResponse;
	});
}

export default useMutateAuthAsAdmin;

import { Grid, Typography } from '@mui/material/index';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

// Our Components
import LiabilitiesList from 'components/Liabilities/LiabilitiesList';
import Loader from 'components/Loader/index';
import { PrimaryButton } from 'components/Button/Button';

// Our Hooks
import useGetProfile from 'hooks/client/useGetProfile';

// Our Routes
import { LOAD_LIABILITIES_ROUTE, PROSPECTIVE_CLIENT_FINAL_ROUTE } from 'routes';

function LiabilitiesPage() {
	const navigate = useNavigate();
	const { data, isLoading, isSuccess } = useGetProfile(
		(userProfile) => userProfile,
		true
	);

	useEffect(() => {
		if (isLoading) {
			navigate(LOAD_LIABILITIES_ROUTE);
		}
	}, [isLoading, navigate]);

	if (isLoading) {
		return <Loader />;
	}

	// eslint-disable-next-line no-unreachable
	if (isSuccess) {
		const { firstName, lastName } = data;

		return (
			<>
				<Typography
					variant="h1Gascogne"
					sx={{ marginLeft: 2, marginTop: 4, marginBottom: 1 }}
				>
					Welcome {firstName} {lastName}
				</Typography>

				<Grid item xs={12} sx={{ marginLeft: 2, marginBottom: 4 }}>
					<Typography variant="body1" sx={{ marginLeft: 1 }}>
						Review your liabilities below.
					</Typography>
				</Grid>

				<LiabilitiesList />

				<Grid
					container
					item
					xs={12}
					md={2}
					xl={1}
					sx={{
						justifyContent: 'center',
						paddingLeft: { xs: 0, md: 2 }
					}}
				>
					<PrimaryButton
						sx={{
							marginBottom: 2,
							width: '90%',
							padding: {
								xs: 2,
								md: 2
							}
						}}
						onClick={() => navigate(PROSPECTIVE_CLIENT_FINAL_ROUTE)}
					>
						Next
					</PrimaryButton>
				</Grid>
			</>
		);
	}
}

export default LiabilitiesPage;

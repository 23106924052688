import { useMutation } from 'react-query';

// Other Hooks
import useMutateAuthAsAdmin from 'hooks/admin/useMutateAuthAsAdmin';
import useMutateMask from 'hooks/admin/useMutateMask';

// Types
/**
 * @typedef {Object} MaskSecrets
 * @property {string} masqSecret
 * @property {boolean} unmasq
 */

function useMutateAddUserMask(getUserToken) {
	// This hooks depends on these hooks
	const authAsAdmin = useMutateAuthAsAdmin();
	const mask = useMutateMask();

	return useMutation(
		/** @param {MaskSecrets} maskSecrets */
		async (maskSecrets) => {
			// When this succeeds it will auth as an admin
			// Authenticate as admin
			await authAsAdmin.mutateAsync({});

			// get Advisor Token here.
			const userProfileResponse = await getUserToken.mutateAsync({});

			const userEmail = userProfileResponse?.email ?? '';

			const maskResponse = await mask.mutateAsync({
				...maskSecrets,
				userEmail
			});

			return maskResponse;
		},
		{
			onError: (error) => {
				console.error('Error when trying to mask account', error);
			}
		}
	);
}

export default useMutateAddUserMask;

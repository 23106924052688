import { useMutation } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoint urls
import { maskAsAnotherUserEndpoint } from 'shared/api-urls';

function useMutateMask() {
	return useMutation(async (maskSecrets, userEmail) => {
		const checkMaskSecretsResponse = await axiosInstance.post(
			maskAsAnotherUserEndpoint,
			{
				...maskSecrets,
				userEmail
			}
		);

		return checkMaskSecretsResponse;
	});
}

export default useMutateMask;

import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print/lib/index';

// Our components
import { SecondaryButton } from 'components/Button/Button';
import ExportableReport from 'components/ExportableReport/ExportableReport';

function ExportableReportPrint({ clientName }) {
	const exportableReportRef = useRef(null);

	const handlePrint = useReactToPrint({
		documentTitle: `${clientName}'s Report`,
		contentRef: exportableReportRef
	});

	return (
		<>
			<SecondaryButton onClick={() => handlePrint()}>
				Download Summary Report
			</SecondaryButton>

			<ExportableReport ref={exportableReportRef} />
		</>
	);
}

export default ExportableReportPrint;

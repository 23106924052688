export const USER_DATA = 'USER_DATA';
export const ADVISOR_DATA = 'ADVISOR_DATA';

// User
export const USER_LIABILITIES = 'USER_LIABILITIES';
export const USER_MORTGAGES = 'USER_MORTGAGES'; // can be serialized as [USER_MORTGAGES, tradeLineId]
export const USER_AUTOS = 'USER_AUTOS'; // can be serialized as [USER_AUTOS, tradeLineId]
export const USER_STUDENTS = 'USER_STUDENTS'; // can be serialized as [USER_STUDENTS, tradeLineId]
export const USER_PERSONALS = 'USER_PERSONALS'; // can be serialized as [USER_PERSONALS, tradeLineId]
export const USER_OTHER_LOANS = 'USER_OTHER_LOANS'; // can be serialized as [USER_OTHER_LOANS, tradeLineId]

// Wallet Overview
export const WALLET_OVERVIEW_DATA = 'WALLET_OVERVIEW_DATA';

// Methodfi Related
export const METHODFI_ENTITY_ID = 'METHODFI_ENTITY_ID';
export const GPT_REWRITTEN_INSIGHTS = 'GPT_REWRITTEN_INSIGHTS'; // Wallet Overview GPT insights

// DTS Related
export const DTS_STATUS = 'DTS_STATUS';
export const BURN_DOWN_CHART_DATA = 'BURN_DOWN_CHART_DATA';
export const SORA_INSIGHTS = 'SORA_INSIGHTS'; // Wallet Overview Insights

// Referral Partner Related
export const REFERRAL_PARTNER_DATA = 'REFERRAL_PARTNER_DATA';

// App State
export const ERROR_MESSAGE_DATA = 'ERROR_MESSAGE_DATA';
export const INFO_MESSAGE_DATA = 'INFO_MESSAGE_DATA';
export const SUCCESS_MESSAGE_DATA = 'SUCCESS_MESSAGE_DATA';

// Asset Provider
export const ASSET_PROVIDER_DATA = 'ASSET_PROVIDER_DATA';
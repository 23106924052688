import { useQuery } from 'react-query';

// Our axios Instance
import axiosInstance from 'services/API/API';

// Sora API endpoint
import { userProfileEndpoint } from 'shared/api-urls';

// React Query Keys
import { USER_DATA } from 'shared/query-keys';

const TWENTY_MINUTES_STALE_TIME = 1000 * 60 * 20;

const TWENTY_MINUTES_CACHE_TIME = TWENTY_MINUTES_STALE_TIME;

// if select is passed. PLEASE pass a fn
function useGetProfile(select, enable) {
	return useQuery(
		USER_DATA,
		async () => {
			const res = await axiosInstance.get(userProfileEndpoint);
			const userProfileData = res.data.data;
			return userProfileData;
		},
		{
			// this staleTime is equivalent to 1 minute
			// this stalTime is added to resolve the navbar refreshing every time we click on a button in the nav bar
			// this will be tweaked post mvp
			enabled: enable,
			staleTime: TWENTY_MINUTES_STALE_TIME,
			cacheTime: TWENTY_MINUTES_CACHE_TIME,
			select: (userProfileData) => {
				if (select) {
					return select(userProfileData);
				}

				return userProfileData;
			}
		}
	);
}

export default useGetProfile;

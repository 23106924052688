import { useQuery } from 'react-query';

// Our Query Keys
import { ASSET_PROVIDER_DATA } from 'shared/query-keys';

// Our Cache
import AssetProvider from 'shared/cache/AssetProvider';

/**
 * This hook is used to get the asset provider data
 * @returns {Object} - The asset provider data
 *
 * This hook is used to get asset provider which is either referral partner or Advisor
 */

function useGetAssetProvider() {
	return useQuery(ASSET_PROVIDER_DATA, async () => {
		// first step
		const assetProvider = new AssetProvider();

		if (!assetProvider.hasAssetProvider()) {
			throw new Error('No asset provider found');
		}

		const assetProviderData = assetProvider.getAssetProvider();

		const data = await new Promise((resolve) => {
			resolve(assetProviderData);
		});

		return data;
	});
}

export default useGetAssetProvider;

import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

// Our Components
import { TertiaryButton } from 'components/Button/Button';
import CardItem from 'components/Cards/CardItem';
import LiabilityAttribute from 'components/Cards/LiabilityAttribute';

// Our Routes
import { DYNAMIC_LIABILITY_ROUTE } from 'routes';

// Our Utility
import { dollarFormatter, roundToDecimal } from 'shared/utils';
import normalizeTradelineType from 'shared/utils/formatting/normalizeTradelineType';

// Our Constants
import {
	AUTO,
	CREDITCARD,
	MORTGAGE,
	STUDENT,
	PERSONAL
} from 'shared/constants';

// Our Assets

import AutoIcon from 'assets/icons/LiabilityIcon/auto_loan.svg';
import CreditCardIcon from 'assets/icons/LiabilityIcon/credit_card.svg';
import HouseIcon from 'assets/icons/LiabilityIcon/house_loan.svg';
import PersonalIcon from 'assets/icons/LiabilityIcon/personal_loan.svg';
import StudentIcon from 'assets/icons/LiabilityIcon/student_loans.svg';

function LoanDataCard({ currentLoanDetails }) {
	const navigate = useNavigate();

	const LoanIcon = useMemo(() => {
		const { tradeLineType } = currentLoanDetails;

		const tradelineToIconMapping = new Map([
			[AUTO, AutoIcon],
			['AUTO_LOAN', AutoIcon],
			[CREDITCARD, CreditCardIcon],
			[MORTGAGE, HouseIcon],
			[PERSONAL, PersonalIcon],
			['PERSONAL_LOAN', PersonalIcon],
			[STUDENT, StudentIcon],
			['STUDENT_LOAN', StudentIcon]
		]);

		const SelectedIcon = tradelineToIconMapping.get(tradeLineType);

		return (
			<Box
				component="img"
				alt={`${tradeLineType}`}
				src={SelectedIcon}
				sx={{
					width: '84px',
					height: '84px',
					alignSelf: 'center'
				}}
			/>
		);
	}, []);

	// Formatted Liability Data
	const interestRate = currentLoanDetails?.interestRate;

	const monthlyPayment = dollarFormatter.format(
		currentLoanDetails?.monthlyMortgagePayment
	);

	const balance = currentLoanDetails?.outstandingBalance;

	const lender = currentLoanDetails?.lender;

	const monthsRemaining = currentLoanDetails?.balanceTenureMonth;

	const formattedInterestRate = `${roundToDecimal(interestRate, 1)}%`;

	const formattedBalance = dollarFormatter.format(balance);

	const { tradeLineType, tradelineId } = currentLoanDetails;

	const dynamicPath = generatePath(DYNAMIC_LIABILITY_ROUTE, {
		tradelineType: tradeLineType?.toLowerCase(),
		tradelineId
	});

	const isCreditCard = currentLoanDetails?.tradeLineType === CREDITCARD;

	const LoanSpecificCardHeading = normalizeTradelineType(tradeLineType);
	return (
		<Grid
			item
			xs={12}
			md={6}
			lg={4}
			sx={{ marginBottom: 4, paddingRight: 2 }}
		>
			<CardItem
				cardHeading={LoanSpecificCardHeading}
				lender={lender}
				LoanIcon={LoanIcon}
				sx={{
					borderRadius: '20px',
					background:
						'linear-gradient(180deg, #D4E7F6 0%, #FFF 100%)',
					boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
				}}
			>
				<LiabilityAttribute title="Balance" value={formattedBalance} />

				<LiabilityAttribute
					title="Interest Rate"
					value={formattedInterestRate}
					gridCol={4}
				/>

				<LiabilityAttribute
					title="Monthly Payment"
					value={monthlyPayment}
				/>

				<LiabilityAttribute
					title="Months Remaining"
					value={monthsRemaining}
					gridCol={4}
				/>

				<Grid item xs={12} md={4}>
					<TertiaryButton
						sx={{
							width: '100%',
							padding: 2,
							visibility: isCreditCard ? 'hidden' : 'visible'
						}}
						onClick={() => navigate(dynamicPath)}
					>
						Update
					</TertiaryButton>
				</Grid>
			</CardItem>
		</Grid>
	);
}

LoanDataCard.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	currentLoanDetails: PropTypes.object.isRequired
};

export default LoanDataCard;

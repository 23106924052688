export const API_URL = process.env.REACT_APP_API_BASE_URL;
export const APP_VERSION = process.env.REACT_APP_VERSION;

// Special
export const maskAsAnotherUserEndpoint = `${API_URL}/v1/info/super`;
export const createClientEndpoint = `${API_URL}/fa/add-client`;

// Methodfi
export const getMethodfiToken = `${API_URL}/mfi-connect`;
export const getMethodfiLiabilities = `${API_URL}/mfi-liabilities/`;

// User
export const getUserLiabilitiesUrl = `${API_URL}/v1/user/liability/`;
export const userProfileEndpoint = `${API_URL}/user/profile`;
export const userOnboardingStatus = `${API_URL}/v1/user/onboarding-status/`;
export const userLoginEndpoint = `${API_URL}/user/login`;

// Prospective Client
export const updateProspectiveClientEmailEndpoint = `${API_URL}/prospect-client/add-email`;
export const getProspectiveAdvisorProfileEndpoint = `${API_URL}/prospect-client/`;

// Client
export const userIncomeEndpoint = `${API_URL}/user/income`;
export const userAssetsEndpoint = `${API_URL}/user/asset`;

// Wallet
export const walletOverviewUrl = `${API_URL}/wallet/overview`;
export const getWalletBurnDown = '/wallet/reports/burndown';

// Rule Engine
export const getSoraInsightsUrl = '/rule/execute';
export const chatGptInsightsUrl = '/v1/gpt/rewrite-advice';

// Referral Partner
export const referralPartnerEndpoint = '/v1/referral-partner/token';

// DTS
export const updateDtsStatusUrl = '/dts/status';

import { useMutation, useQueryClient } from 'react-query';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoint urls
import {
	userLoginEndpoint as userLoginUrl,
	updateProspectiveClientEmailEndpoint
} from 'shared/api-urls';

// Our Query Keys
import { USER_DATA } from 'shared/query-keys';

function useMutateProgramaticAuth(setBeginMethodfiAuth) {
	const auth = getAuth();
	const queryClient = useQueryClient();

	return useMutation(
		async ({ clientEmail, clientPassword }) => {
			const firebaseResponse = await signInWithEmailAndPassword(
				auth,
				clientEmail,
				clientPassword
			);

			const firebaseIdToken = firebaseResponse.user.accessToken;

			const loginResponse = await axiosInstance.post(userLoginUrl, {
				firebaseIdToken
			});

			const userProfileData = loginResponse.data.data;

			try {
				const { id: userId, email } = userProfileData;

				const updateProspectiveResponse = await axiosInstance.post(
					updateProspectiveClientEmailEndpoint,
					{ userId, email }
				);
			} catch (e) {
				console.error(e);
			}

			return userProfileData;
		},
		{
			onSuccess: (userProfile) => {
				queryClient.setQueryData(USER_DATA, userProfile);
				setBeginMethodfiAuth(true);
			},

			onError: (error) => {
				console.error('Failed to authenticate as user...');
				console.error(error);
			}
		}
	);
}

export default useMutateProgramaticAuth;

// Utils
import sentenceCapitalize from 'shared/utils/formatting/sentenceCapitalize';

// Constants
import { CREDITCARD } from 'shared/constants';

function normalizeTradelineType(tradelineType, optionalMutation = null) {
	if (tradelineType === CREDITCARD) {
		return optionalMutation
			? optionalMutation('Credit Card')
			: 'Credit Card';
	}

	if (tradelineType.includes('_')) {
		const splitType = tradelineType.split('_');
		const tradelineTypeWithoutSplit = splitType[0];
		return optionalMutation
			? optionalMutation(sentenceCapitalize(tradelineTypeWithoutSplit))
			: sentenceCapitalize(tradelineTypeWithoutSplit);
	}

	return optionalMutation
		? optionalMutation(sentenceCapitalize(tradelineType))
		: sentenceCapitalize(tradelineType);
}

export default normalizeTradelineType;

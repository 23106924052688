import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Our Hooks
import useMutateAddUserMask from 'hooks/admin/useMutateAsAnotherUser';

// Local constants
const MASQ_SECRET = process.env.REACT_APP_ADMIN_SECRET;

/**
 * This hook is used to tempoarily authenticate as another user.
 * It will redirect to the appropriate page based on the user's role.
 * @param {string} route - The route to redirect to.
 * @param {boolean} isSuccess - Whether the user is authenticated.
 * @param {function} setIsSuccess - The function to set the isSuccess state.
 * @param {function} getUserToken - The token to get the user's profile.
 */
function useUtilAuthenticateAndRedirect(
	route,
	isSuccess,
	setIsSuccess,
	getUserToken
) {
	const navigate = useNavigate();
	const maskAsUser = useMutateAddUserMask(getUserToken);

	useEffect(() => {
		maskAsUser.mutate(
			{
				masqSecret: MASQ_SECRET,
				unmasq: false
			},
			{
				onSuccess: () => {
					setIsSuccess(true);
				}
			}
		);
	}, []);

	useEffect(() => {
		if (isSuccess) {
			navigate(route, {});
		}
	}, [isSuccess]);
}

export default useUtilAuthenticateAndRedirect;

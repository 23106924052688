import { useMutation } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoint urls
import { createClientEndpoint } from 'shared/api-urls';

// Our Hooks
import useGetAssetProvider from 'hooks/assetProvider/useGetAssetProvider';
import useMutateSendStandardEmail from 'hooks/emails/useMutateSendStandardEmail';
import useMutateSendAccountCreationLog from 'hooks/log/useMutateSendAccountCreationLog';

function useMutateCreateClient() {
	const sendEmail = useMutateSendStandardEmail();
	const sendLog = useMutateSendAccountCreationLog();
	const { data } = useGetAssetProvider();

	return useMutation(
		async ({ accountCreationPayload, logPayload }) => {
			const response = await axiosInstance.post(
				createClientEndpoint,
				accountCreationPayload
			);

			return {
				accountCreationResponse: response,
				logPayload
			};
		},
		{
			onSuccess: async ({ accountCreationResponse, logPayload }) => {
				const clientData = accountCreationResponse.data.data;

				const { data: advisorProfileData } = data;

				const {
					id: clientId,
					firstName,
					lastName,
					phoneNumber,
					dob,
					email
				} = clientData;

				const {
					email: advisorEmail,
					firstName: advisorFirstName,
					lastName: advisorLastName,
					companyName
				} = advisorProfileData;

				sendLog.mutate({
					onboardingRequestPayload: {
						...logPayload,
						clientId
					}
				});

				const emailSubject = `Prospective Client ${firstName} ${lastName} for ${advisorFirstName} ${advisorLastName} from advisor firm ${companyName} has signed up`;
				const emailBody = `
					Details:

					Prospective Client first name: ${firstName}
					Prospective Client last name: ${lastName}
					Prospective Client phone number: ${phoneNumber}
					Prospective Client dob: ${dob}

					Advisor name: ${advisorFirstName} ${advisorLastName}
					Advisor email: ${advisorEmail}
				`;

				sendEmail.mutate({
					emailSubject,
					emailBody,
					prospectiveClientInfo: { firstName, lastName, email }
				});
			},
			onError: (error) => {
				console.error('Error when trying to mask account', error);
			}
		}
	);
}

export default useMutateCreateClient;

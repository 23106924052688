import { Box, Typography } from '@mui/material';

function AssetProviderBox({ providerData }) {
	const {
		profilePicUrl: providerProfilePic,
		firstName,
		lastName,
		companyName
	} = providerData;

	return (
		<Box sx={{ paddingLeft: 2 }}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'flex-start',
					marginTop: 4,
					marginBottom: 4
				}}
			>
				<Box
					component="img"
					src={providerProfilePic}
					sx={{
						maxHeight: 80,
						maxWidth: '50%',
						borderRadius: 3.14 * Math.pow(2, 4),
						marginBottom: 2
					}}
				/>

				<Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
					{firstName} {lastName},
				</Typography>

				<Typography variant="body2">{companyName}</Typography>
			</Box>
		</Box>
	);
}

export default AssetProviderBox;

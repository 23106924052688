import { Grid } from '@mui/material/index';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

// Our Components
import LiabilityCard from 'components/Cards/LiabilityCard';
import LiabilitiesLoading from 'components/Liabilities/LiabilitiesLoading';

// Our Hooks
import useGetAllUserLiabilities from 'hooks/client/useGetAllUserLiabilities';

// Our Routes
import { LOAD_LIABILITIES_ROUTE } from 'routes/index';

function LiabilitiesList() {
	const navigate = useNavigate();
	const { isLoading, isSuccess, data } = useGetAllUserLiabilities(true);

	useEffect(() => {
		if (isLoading) navigate(LOAD_LIABILITIES_ROUTE);
	}, [isLoading, navigate]);

	if (isLoading) {
		return <LiabilitiesLoading />;
	}

	if (isSuccess) {
		return (
			<Grid container item>
				{data.map((currentLiability) => (
					<LiabilityCard
						key={uuidv4()}
						currentLoanDetails={currentLiability}
					/>
				))}
			</Grid>
		);
	}
}

export default LiabilitiesList;

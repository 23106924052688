import { Link as ExternalLink, Typography } from '@mui/material';

function Footer() {
	return (
		<>
			<Typography
				variant="subtitle1Link"
				sx={{
					textDecoration: 'none',
					fontSize: { xs: '0.65em', sm: '1em' }
				}}
			>
				© 2025 Sora Finance
			</Typography>
			<ExternalLink
				href="https://www.sorafinance.com/privacy-policy"
				variant="subtitle1Link"
				sx={{
					fontSize: { xs: '0.65em', sm: '1em' }
				}}
			>
				Privacy Policy
			</ExternalLink>
			<ExternalLink
				href="https://www.sorafinance.com/terms-of-service"
				variant="subtitle1Link"
				sx={{
					fontSize: { xs: '0.65em', sm: '1em' }
				}}
			>
				Terms of Service
			</ExternalLink>
		</>
	);
}

export default Footer;

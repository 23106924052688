// React Query Defaults
const DEFAULT_STALE_TIME_IN_MINUTES = 1000 * 60;
const DEFAULT_STALE_TIME_IS_FIVE_MINUTES = 5;

/**
 * Calculates the stale time in milliseconds for React Query
 * @param {number} [numberOfMinutes] - The number of minutes to use for the stale time
 * @returns {number} [numberOfMinutes] - * 1000 * 60 The stale time in milliseconds
 */

const getStaleTime = (numberOfMinutes = DEFAULT_STALE_TIME_IS_FIVE_MINUTES) =>
	numberOfMinutes * DEFAULT_STALE_TIME_IN_MINUTES;

export default getStaleTime;

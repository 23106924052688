import { Grid } from '@mui/material';
import { useState } from 'react';

// Our Components
import SimpleGradientLoading from 'components/Loader/SimpleGradientLoading';

// Our Hooks
import useUtilAuthenticateAndRedirect from 'hooks/admin/useUtilAuthenticateAndRedirect';
import useMutateGetAdvisorProfileViaToken from 'hooks/admin/useMutateGetAdvisorProfileViaToken';

// Our Routes
import { PROSPECTIVE_CLIENT_INTRO_ROUTE } from 'routes';

function VerifyAdvisor() {
	const [isAuthSuccess, setIsAuthSuccess] = useState(false);

	const getUserToken = useMutateGetAdvisorProfileViaToken();

	// This does a lot please review
	useUtilAuthenticateAndRedirect(
		PROSPECTIVE_CLIENT_INTRO_ROUTE,
		isAuthSuccess,
		setIsAuthSuccess,
		getUserToken
	);

	return (
		<Grid container>
			<SimpleGradientLoading
				loadingMessage="Loading..."
				loadingSubMessage=""
			/>
		</Grid>
	);
}

export default VerifyAdvisor;

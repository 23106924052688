import { Grid } from '@mui/material';
import { useState } from 'react';

// Our Components
import SimpleGradientLoading from 'components/Loader/SimpleGradientLoading';

// Our Hooks
import useUtilAuthenticateAndRedirect from 'hooks/admin/useUtilAuthenticateAndRedirect';
import useMutateGetReferralPartnerProfileViaToken from 'hooks/referralPartner/useMutateGetReferralProfileViaToken';

// Our Routes
import { REFERRAL_PARTNER_INTRO_ROUTE } from 'routes';

function VerifyReferralPartner() {
	const [isAuthSuccess, setIsAuthSuccess] = useState(false);

	const getUserToken = useMutateGetReferralPartnerProfileViaToken();

	useUtilAuthenticateAndRedirect(
		REFERRAL_PARTNER_INTRO_ROUTE,
		isAuthSuccess,
		setIsAuthSuccess,
		getUserToken
	);

	return (
		<Grid container>
			<SimpleGradientLoading
				loadingMessage="Loading..."
				loadingSubMessage=""
			/>
		</Grid>
	);
}

export default VerifyReferralPartner;

import { useMutation } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoint urls
import { referralPartnerEndpoint } from 'shared/api-urls';

// Our Cache Provider
import AssetProvider from 'shared/cache/AssetProvider';

// Our Constants
import { ASSET_PROVIDER_REFERRAL_PARTNER } from 'shared/constants';

function useMutateGetReferralPartnerViaToken() {
	const { token } = useParams();
	const navigate = useNavigate();

	return useMutation(
		async () => {
			const response = await axiosInstance.get(
				`${referralPartnerEndpoint}/${token}`
			);

			const referralPartnerProfile = response.data.data;

			const normalizedReferralPartnerProfile = {
				...referralPartnerProfile,
				id: referralPartnerProfile.partnerId,
				profilePicUrl: referralPartnerProfile.logoUrl,
				firstName: referralPartnerProfile.partnerFirstname,
				lastName: referralPartnerProfile.partnerLastname,
				companyName: referralPartnerProfile.companyName || null
			};

			return normalizedReferralPartnerProfile;
		},
		{
			onError: (error) => {
				navigate('/404');
				console.error(
					'Error when trying to get referral partner profile',
					error
				);
			},
			onSuccess: (data) => {
				const assetProvider = new AssetProvider({
					data,
					type: ASSET_PROVIDER_REFERRAL_PARTNER,
					token,
					onboardingSourceType: 'REFERRAL_PARTNER',
					onboardingSourceId: data.partnerId
				});
				assetProvider.saveAssetProvider();
			}
		}
	);
}

export default useMutateGetReferralPartnerViaToken;

import { ASSET_PROVIDER_DATA } from 'shared/query-keys';

// Our Utils
import { dataIsValid } from 'shared/utils';

/**
 * This class is used to cache the asset provider data
 * @class AssetProvider
 * @constructor
 * @param {Object} assetProvider - The asset provider data
 * @returns {Object} - The asset provider data
 */
class AssetProvider {
	#assetProviderKey = ASSET_PROVIDER_DATA;
	#assetProviderData;
	#assetProviderType;
	#assetProviderToken;
	#assetProviderOnboardingSourceType;
	#assetProviderOnboardingSourceId;

	constructor(newAssetProviderData) {
		const isExistingAssetProviderData = !dataIsValid(newAssetProviderData);

		if (isExistingAssetProviderData) {
			if (!this.hasAssetProvider()) {
				throw new Error('Invalid asset provider data');
			}

			try {
				const cachedData = JSON.parse(
					localStorage.getItem(this.#assetProviderKey)
				);
				const {
					data,
					type,
					token,
					onboardingSourceType,
					onboardingSourceId
				} = cachedData;
				this.setAssetProvider(
					data,
					type,
					token,
					onboardingSourceType,
					onboardingSourceId
				);
				return;
			} catch (error) {
				throw new Error('Invalid asset provider data');
			}
		}

		const cachedDataString =
			this.hasAssetProvider() &&
			localStorage.getItem(this.#assetProviderKey);

		if (cachedDataString) {
			const cachedData = JSON.parse(cachedDataString);
			if (cachedData.token === newAssetProviderData.token) {
				const {
					data,
					type,
					token,
					onboardingSourceType,
					onboardingSourceId
				} = cachedData;
				this.setAssetProvider(
					data,
					type,
					token,
					onboardingSourceType,
					onboardingSourceId
				);
				return;
			}
			// Clear cached data since tokens don't match
			localStorage.removeItem(this.#assetProviderKey);
		}

		const { data, type, token, onboardingSourceType, onboardingSourceId } =
			newAssetProviderData;
		this.setAssetProvider(
			data,
			type,
			token,
			onboardingSourceType,
			onboardingSourceId
		);
	}

	hasAssetProvider() {
		return localStorage.getItem(this.#assetProviderKey) !== null;
	}

	setAssetProvider(
		assetProviderData,
		assetProviderType,
		assetProviderToken,
		assetProviderOnboardingSourceType,
		assetProviderOnboardingSourceId
	) {
		this.#assetProviderData = assetProviderData;
		this.#assetProviderType = assetProviderType;
		this.#assetProviderToken = assetProviderToken;
		this.#assetProviderOnboardingSourceType =
			assetProviderOnboardingSourceType;
		this.#assetProviderOnboardingSourceId = assetProviderOnboardingSourceId;
	}

	getAssetProvider() {
		return {
			data: this.#assetProviderData,
			type: this.#assetProviderType,
			token: this.#assetProviderToken,
			onboardingSourceType: this.#assetProviderOnboardingSourceType,
			onboardingSourceId: this.#assetProviderOnboardingSourceId
		};
	}

	getAssetProviderType() {
		return this.#assetProviderType;
	}

	getAssetProviderToken() {
		return this.#assetProviderToken;
	}

	getAssetProviderData() {
		return this.#assetProviderData;
	}

	getAssetProviderOnboardingSourceType() {
		return this.#assetProviderOnboardingSourceType;
	}

	getAssetProviderOnboardingSourceId() {
		return this.#assetProviderOnboardingSourceId;
	}

	stringifyAssetProvider() {
		return JSON.stringify({
			data: this.#assetProviderData,
			type: this.#assetProviderType,
			token: this.#assetProviderToken,
			onboardingSourceType: this.#assetProviderOnboardingSourceType,
			onboardingSourceId: this.#assetProviderOnboardingSourceId
		});
	}

	saveAssetProvider() {
		localStorage.setItem(
			this.#assetProviderKey,
			this.stringifyAssetProvider()
		);
	}
}

export default AssetProvider;

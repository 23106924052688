export const PROSPECTIVE_ADD_CLIENT_ROUTE = '/prospective/client/create';

export const IDENTIFIED_LIABILITIES_ROUTE =
	'/prospective/client/liabilities/identified';

export const PROSPECTIVE_CLIENT_INTRO_ROUTE = '/prospective/client/intro';
export const PROSPECTIVE_CLIENT_FINAL_ROUTE = '/prospective/client/final';
export const PROSPECTIVE_CLIENT_DTA_ROUTE = '/prospective/client/dta';
export const LOAD_LIABILITIES_ROUTE = '/prospective/client/liabilities/loading';

export const DYNAMIC_LIABILITY_ROUTE =
	'/prospective/client/update/:tradelineType/:tradelineId';

// Method Related Routes
export const METHODFI_UNABLE_TO_VERIFY_ROUTE =
	'/prospective/client/unable-to-verify';

// Client Onboarding Additional(manual) Routes
export const AUTO_LIABILITY_ADDITIONAL_ROUTE =
	'/prospective/client/update/auto';

export const HOME_LIABILITY_ADDITIONAL_ROUTE =
	'/prospective/client/update/mortgage';

export const STUDENT_LIABILITY_ADDITIONAL_ROUTE =
	'/prospective/client/update/student';

export const PERSONAL_LIABILITY_ADDITIONAL_ROUTE =
	'/prospective/client/update/personal';

export const VERIFY_ADVISOR_ROUTE = 'verify/advisor';

// Referral Partner Related
export const VERIFY_REFERRAL_PARTNER_ROUTE = 'verify/referral-partner';
export const REFERRAL_PARTNER_INTRO_ROUTE =
	'/referral-partner/client/intro';

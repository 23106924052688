import { useMutation } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoint urls
import { getProspectiveAdvisorProfileEndpoint } from 'shared/api-urls';

// Our Cache Provider
import AssetProvider from 'shared/cache/AssetProvider';

// Our Constants
import { ASSET_PROVIDER_ADVISOR } from 'shared/constants';

function useMutateGetAdvisorProfileViaToken() {
	const { token } = useParams();
	const navigate = useNavigate();

	return useMutation(
		async () => {
			const response = await axiosInstance.get(
				`${getProspectiveAdvisorProfileEndpoint}${token}`
			);
			const advisorProfile = response.data.data;

			return advisorProfile;
		},
		{
			onError: (error) => {
				navigate('/404');
				console.error('Error when trying to mask account', error);
			},

			onSuccess: (data) => {
				const assetProvider = new AssetProvider({
					data,
					type: ASSET_PROVIDER_ADVISOR,
					token,
					onboardingSourceType: 'SELF',
					onboardingSourceId: data.id
				});
				assetProvider.saveAssetProvider();
			}
		}
	);
}

export default useMutateGetAdvisorProfileViaToken;
